import React from 'react';
import './VerticalPad.css';

interface VerticalPadProps {
  height: number;
}

const VerticalPad: React.FC<VerticalPadProps> = ({ height }) => {
  return (
    <div
      className="vertical-pad"
      style={{ '--height': `${height}px` } as React.CSSProperties}
    ></div>
  );
};

export default VerticalPad;