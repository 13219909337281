interface ThemeColors {
  companyName: string,
  logoBackground: string;
  logoName: any,
  navLink: string,
  navLinkActive: string,
  trialButtonBackground: string,
  h1: string,
  h1SubText: string,
  demoCardBackground: string,
  demoButton: string,
  featureSectionBackground: string;
  featureSubHeadline: string;
  featureText: string,
  featureCardHeaderNumberBackground: string,
  featureCardHeaderTitleBackground: string,
  contactBackground: string,
  background: string,
  footerBackground: string,
  contactText: string,
  contactButton: string,
}

interface Themes {
  leafcall: ThemeColors;
}

const themes: Themes = {
  leafcall: (() => {
    const darkGreen = '#36563E';
    const brown = '#907350';
    const green = '#5A8B5D';
    const lightGreen = '#BEC991';
    const white = '#FEFFF8';
    const darkBrown = '#604320';
    const black = '#16361E';
    return {
      companyName: 'Leaf Call AI',
      logoBackground: darkGreen,
      logoName: createGradientText(darkGreen, green),
      navLink: darkGreen,
      navLinkActive: brown,
      trialButtonBackground: brown,
      h1: darkGreen,
      h1SubText: darkGreen,
      demoCardBackground: brown,
      demoButton: darkBrown,
      featureSectionBackground: green,
      featureSubHeadline: darkGreen,
      featureText: black,
      featureCardHeaderNumberBackground: darkBrown,
      featureCardHeaderTitleBackground: brown,
      footerBackground: brown,
      contactBackground: brown,
      contactText: black,
      background: white,
      contactButton: darkGreen,
    };
  })(),
};

export function createGradientText(startColor: string, endColor: string) {
  return {
    fontWeight: 'bold',
    WebkitFontSmoothing: 'antialiased',
    backgroundImage: `linear-gradient(to bottom right, ${startColor}, ${endColor})`,
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    color: 'transparent',
  }
}

export default themes;