import React from 'react';
import '../styles/Footer.css';
import { useTheme, useThemeColors } from '../ThemeContext';

const Footer: React.FC = () => {
  const theme = useThemeColors();
  const themeName = useTheme();
  return (
    <footer className="footer" style={{ background: theme.footerBackground }}>
      <div className="footer-content">
        <img src="leaf-call-logo-horizontal-white.png" alt="Logo" style={{ width: '200px' }} />
      </div>
    </footer>
  );
};

export default Footer;