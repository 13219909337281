import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Demo } from '../App';
import { useTheme, useThemeColors } from '../ThemeContext';
import { navLink, navLinkHover, navLinkActive, navLinkUnderline } from '../styles/style';

interface NavigationProps {
  demo: Demo | null;
}

const Navigation: React.FC<NavigationProps> = ({ demo }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('demo');
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useThemeColors();
  const themeName = useTheme();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };

    const handleScroll = () => {
      const sections = ['demo', 'features'];
      if (demo) sections.push('pricing');
      sections.push('contact');
      const scrollPosition = window.scrollY;

      setIsScrolled(scrollPosition > 0);

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element && scrollPosition >= element.offsetTop - 100) {
          setActiveLink(section);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial active link and scroll state
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - (id === 'contact' ? 0 : 80);
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    setIsMenuOpen(false);
  };

  const getLinkStyle = (linkId: string) => {
    const baseStyle = navLink({ color: theme.navLink });
    if (activeLink === linkId) {
      return {
        ...baseStyle,
        ...navLinkActive({ color: theme.navLink, activeColor: theme.navLinkActive }),
      };
    }
    return baseStyle;
  };

  const renderNavLink = (to: string, text: string) => (
    <div style={{ position: 'relative' }}>
      <Link
        to={to}
        style={getLinkStyle(to.slice(1))}
        onClick={() => handleLinkClick(to.slice(1))}
      >
        {text}
      </Link>
      {activeLink === to.slice(1) && (
        (() => {
          let style = { color: theme.navLink, activeColor: theme.navLinkActive } as any;
          console.log(style);
          style = navLinkUnderline(style);
          console.log(style);
          return <span style={style} />
        })()
      )}
    </div>
  );

  useEffect(() => {
    if (themeName === 'leafcall') {
      const stops = document.querySelector('robot-logo')?.querySelectorAll('stop');
      if (stops) {
        stops[0].setAttribute('stop-color', '#ff0000');
      }
    }
  }, []);

  return (
    <nav className={`fixed-top ${isScrolled ? 'scrolled' : ''}`}>
      <div className="nav-background"></div>
      <div className="nav-content">
        <div className="nav-wrapper">
          <div className="nav-left">
            <img src="leaf-call-logo-horizontal.png" alt="Logo" width="200px" />
          </div>
          <div className="nav-center" style={{ marginTop: '12px', marginLeft: '22px' }}>
            {renderNavLink('#demo', 'Demo')}
            {renderNavLink('#features', 'Features')}
            {demo && renderNavLink('#pricing', 'Pricing')}
            {renderNavLink('#contact', 'Contact')}
          </div>
          <div className="nav-right">
            {/* <button
              className="button btn-trial"
              style={{ backgroundColor: theme.trialButtonBackground }}
              onClick={() => handleLinkClick('contact')}
            >
              1 Month Free
            </button> */}
            {isMobile && (
              <button className="btn btn-hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <span className="navbar-toggler-icon"></span>
              </button>
            )}
          </div>
        </div>
      </div>
      {isMobile && (
        <div className={`mobile-menu ${isMenuOpen ? 'open' : 'closed'}`}>
          <button className="btn btn-close mb-3" onClick={() => setIsMenuOpen(false)}></button>
          <div className="d-flex flex-column">
            {renderNavLink('#demo', 'Demo')}
            {renderNavLink('#features', 'Features')}
            {demo && renderNavLink('#pricing', 'Pricing')}
            {renderNavLink('#contact', 'Contact')}
            {/* <button
              className="button  btn-trial mt-3"
              style={{ backgroundColor: theme.trialButtonBackground }}
              onClick={() => handleLinkClick('contact')}
            >
              No Strings Trial
            </button> */}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;