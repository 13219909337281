import React from 'react';
import { useThemeColors } from '../ThemeContext';

interface CustomShapeDividerProps {
  flip?: boolean;
}

const CustomShapeDivider: React.FC<CustomShapeDividerProps> = ({ flip = true }) => {
  const themeColors = useThemeColors();
  return (
    <div className="custom-shape-divider-wrapper" style={{ width: '100%', overflow: 'hidden' }}>
      <div className={`custom-shape-divider ${flip ? 'flip' : ''}`}>
        <svg viewBox="0 0 5000 300" width="5000px" height="300px" xmlns="http://www.w3.org/2000/svg">
          <path d="M 0 0 L 0 115.73 C 199.121 171.23 431.64 196.16 658.293 185.73 C 951.441 172.3 1226.308 102.45 1519.906 91.98 C 1827.554 81.08 2134.618 134.18 2429.017 180.13 C 2717.632 225.13 3005.231 242.33 3301.463 212.83 C 3452.087 197.83 3592.495 168.23 3736.653 139.48 C 4122.629 62.5 4637.214 -35.73 4999.691 131.18 L 4999.691 0 L 0 0 Z" opacity=".25" style={{ fill: themeColors.featureSectionBackground }} transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)" />
          <path d="M 0 0 L 0 39.53 C 54.197 92.3 115.23 142.15 198.826 180.13 C 414.445 278.18 687.88 277.5 936.267 228.95 C 1066.138 203.57 1186.788 163.77 1310.106 129.45 C 1480.7 81.95 1663.351 14.45 1855.541 5.27 C 2006.707 -1.86 2151.12 28.82 2266.601 84.17 C 2399.057 147.65 2526.411 239.17 2698.639 266.67 C 2867.232 293.65 3037.793 249.94 3195.296 205.97 C 3352.8 162 3508.636 108.47 3682.732 98.34 C 3931.753 83.71 4154.993 155.54 4386.871 195.44 C 4512.773 217.09 4632.84 210.87 4749.954 176.69 C 4843.473 149.46 4950.065 109.36 5002.81 53.59 L 5002.81 0 L 0 0 Z" opacity=".5" style={{ fill: themeColors.featureSectionBackground }} transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)" />
          <path d="M 0 0 L 0 14.08 C 625.45 147.5 1310.253 178.3 1984.96 106.43 C 2164.337 87.33 2336.339 56.13 2517.301 40.28 C 2763.423 18.7 2986.518 70.88 3207.944 128.78 C 3453.766 193.05 3696 238.1 3967.985 225 C 4328.958 207.5 4687.412 110.72 5005.869 12.97 L 5005.869 0 L 0 0 Z" style={{ fill: themeColors.featureSectionBackground }} transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)" />
        </svg>
      </div>
    </div>
  );

};

export default CustomShapeDivider;