import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Demo } from '../App';
import { addDays, format } from 'date-fns';
import { useTheme, useThemeColors } from '../ThemeContext';

interface SectionDemoProps {
  phoneNumber: string;
  onPhoneNumberChange: (newPhoneNumber: string) => void;
  demo: Demo | null;
  emailFrom: string;
  emailTo: string | string[];
}

// call flow point 1 bak: ${!demo ? 'Ask the user for their name and what company and industry they belong to and what their role is there.' : `Confirm the first name of the person you're talking too. If it is ${demo.first_name}, assume the last name, company name, industry and role you have are also correct and if you're not positive you're pronouncing their name correctly, confirm the pronunciation. If it's not ${demo.first_name}, ask the user for their name and what company and industry they belong to and what their role is there.`}

const makePrompt = (demo: Demo | null) => `
## Agent Identity
- Your name is Alex
- You are a phone agent for Leaf Call AI
- This is a demo call initiated by the user from our website${demo ? ', they entered their phone number on the website and the rest of the user information was obtained before contacting them.' : ''}
${demo
    ? `
  ## User Information
  ${demo.first_name ? `- Name: ${demo.first_name}${demo.last_name ? ` ${demo.last_name}` : ''}` : ``}
  - Role: ${demo.role || "important decision maker"}
  ${demo.company_name ? `- Company: ${demo.company_name}` : ''}
  ${demo.industry ? `- Industry: ${demo.industry}` : ''}
  ${demo.crm_type ? `- Integrates with: ${demo.crm_type}` : ''}
  `
    : ``
  }
  ${demo?.notes ? `Other notes about this user and/or their company: ${demo.notes}` : ''}

## Company Overview
- Leaf Call AI provides AI phone answering agents to businesses
- Our service is highly customizable to meet specific business needs

## Key Features
1. Dynamic Call Routing: Route calls based on predefined conditions
2. Message Handling: Take messages and send automatic emails to respective departments
3. Custom Voice Selection: Choose from a curated voice library or personalize with a voice clone
4. Software Integration: Connect with menu
5. Receptionist Services: Optimize operations with 24/7 support
6. Product Service: Deliver exceptional product service and support around the clock

## Call Flow
1. Greet the user "Hi${demo ? ' ' + demo.first_name : ''}, I'm Alex from Leaf Call AI I'm an AI phone agent, but you can talk to me like a person".
2. Give a brief value proposition with a couple of examples
3. Offer to demonstrate how a customized AI agent might sound for their specific business
4. If interested, transition into a role-play as an agent for Green Palace, a medical marijuana dispensary
- Engage in a brief exchange to demonstrate capabilities
- Return to the main conversation after the role-play
5. Ask about their current business challenges related to phone communications
6. Highlight benefits of AI phone agents for their company, referencing key features
7. Tell the user you're here to answer any questions they might have about AI phone agents and to explore how this technology could potentially help their business
8. Address any concerns, emphasizing value proposition

## Communication Style
- Be professional yet personable and natural
- Demonstrate how you can adapt to their industry
- Answer questions thoroughly, showcasing your knowledge
- IMPORTANT: End each response with a question to maintain engagement

## Key Objectives
- Generate excitement about AI phone agent benefits
- Address concerns proactively
- Illustrate adaptability to their specific industry
- Emphasize the customizable nature of the service

## Additional Guidelines
- Use active listening techniques
- Provide specific examples of how the service can benefit their industry
- Offer to schedule a follow-up call or demo if needed
- Be prepared to discuss pricing models and implementation processes
- Highlight any relevant case studies or success stories
- We can create a custom agent for a business and allow them to try it for 7 days completely free of charge or obligation.
- Do mention any specific prices

## Closing the Call
- Ask the user if they mind if a member of our friendly sales staff can reach out to answer any additional questions
- If they say they do mind, ask them to enter their email at the bottom of the webpage, so someone can reach out via email
- Thank the user for their time
- Express excitement about potentially joining their team

Remember to adapt your responses based on the user's level of interest and concerns throughout the call.
`;

const SectionDemo: React.FC<SectionDemoProps> = ({ phoneNumber: parentPhoneNumber, onPhoneNumberChange, demo, emailFrom, emailTo }) => {
  const [localPhoneNumber, setLocalPhoneNumber] = useState(parentPhoneNumber);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCallInProgress, setIsCallInProgress] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const sectionRef = useRef<HTMLElement>(null);
  const theme = useThemeColors();
  const themeName = useTheme();

  const validatePhoneNumber = (phone: string) => {
    const digitsOnly = phone.replace(/\D/g, '');
    return digitsOnly.length >= 10 && digitsOnly.length <= 11;
  };

  const formatPhoneNumber = (phone: string) => {
    const digitsOnly = phone.replace(/\D/g, '');
    return digitsOnly.startsWith('1') ? `+${digitsOnly}` : `+1${digitsOnly}`;
  };

  const sendEmail = async (prompt: string, formattedPhoneNumber: string, callResult: any) => {
    try {
      const response = await axios.post(
        'https://zsrbhkwknfhpqrvkzipj.supabase.co/functions/v1/resend-proxy',
        {
          from: emailFrom,
          to: emailTo,
          subject: 'Website: New Demo Call' + (demo ? ' (' + demo.first_name + ' ' + demo.last_name + ')' : ''),
          html: `
            <h1>New Demo Call Information</h1>
            <h2>Prompt:</h2>
            <pre>${prompt}</pre>
            <h2>Formatted Phone Number:</h2>
            <p>${formattedPhoneNumber}</p>
            <h2>Call Result:</h2>
            <pre>${JSON.stringify(callResult, null, 2)}</pre>
          `
        }
      );
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const makeCall = async (formattedPhoneNumber: string) => {
    setIsCallInProgress(true);
    setErrorMessage('');

    const prompt = makePrompt(demo);

    try {
      const callResult = await sendCallAndGetTranscript(
        "https://fourspot.api.bland.ai",
        "sub-sk-d4edac50-d103-48b3-a5f4-e238dbf4ba65-0791d7f9-e61c-4a9a-b7fd-4160a457c085",
        formattedPhoneNumber,
        prompt
      );

      // Send email after the call but before checking status
      await sendEmail(prompt, formattedPhoneNumber, callResult);

      if (callResult.status === 'failed') {
        throw new Error('Call status is "failed"');
      }

      console.log('Call completed');
      console.log(`Transcript: ${callResult.transcript}`);
      console.log(`Call Length: ${callResult.callLength}`);
      console.log(`Summary: ${callResult.summary}`);

    } catch (error) {
      console.error('Error making call:', error);
      setErrorMessage('Hmm... couldn\'t get through.\nPlease double-check the number and try again.');
    } finally {
      setIsCallInProgress(false);
    }
  };

  async function sendCallAndGetTranscript(blandUrl: string, apiKey: string, phoneNumber: string, prompt: string) {
    try {
      // Send the call
      const callResponse = await axios.post(`${blandUrl}/v1/calls`, {
        phone_number: phoneNumber,
        task: prompt,
        model: "enhanced",
        voice: "maya",
        record: true,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': apiKey
        }
      });

      if (callResponse.data.status !== 'success') {
        throw new Error('Failed to initiate call');
      }

      const callId = callResponse.data.call_id;

      // Poll for call completion
      let callCompleted = false;
      while (!callCompleted) {
        await new Promise(resolve => setTimeout(resolve, 500)); // Wait 5 seconds between checks

        const callDetails = await axios.get(`${blandUrl}/v1/calls/${callId}`, {
          headers: { 'authorization': apiKey }
        });

        if (callDetails.data.completed) {
          callCompleted = true;
        }
      }

      // Get final call details
      const finalCallDetails = await axios.get(`${blandUrl}/v1/calls/${callId}`, {
        headers: { 'authorization': apiKey }
      });

      return {
        callId: finalCallDetails.data.call_id,
        transcript: finalCallDetails.data.concatenated_transcript,
        callLength: finalCallDetails.data.call_length,
        summary: finalCallDetails.data.summary,
        status: finalCallDetails.data.status
      };
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  const validateAndUpdateState = useCallback((phone: string) => {
    const valid = validatePhoneNumber(phone);
    setIsPhoneValid(valid);
    if (phone.length > 0 && !valid) {
      setErrorMessage('Please enter a valid phone number');
    } else {
      setErrorMessage('');
    }
    return valid;
  }, []);

  const handleButtonClick = () => {
    setIsButtonClicked(true);
    const isValid = validateAndUpdateState(localPhoneNumber);
    if (isValid && localPhoneNumber.length > 0) {
      const formattedPhoneNumber = formatPhoneNumber(localPhoneNumber);
      makeCall(formattedPhoneNumber);
    } else {
      setErrorMessage('Please enter a valid phone number');
    }
  };

  const handlePhoneNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = e.target.value.replace(/\D/g, '').slice(0, 11);
    setLocalPhoneNumber(newPhoneNumber);
    // onPhoneNumberChange(newPhoneNumber);
  }, [onPhoneNumberChange]);

  const handlePhoneNumberBlur = useCallback(() => {
    validateAndUpdateState(localPhoneNumber);
  }, [localPhoneNumber, validateAndUpdateState]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target as Node)) {
        setErrorMessage('');
        setIsPhoneValid(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLocalPhoneNumber(parentPhoneNumber);
  }, [parentPhoneNumber]);

  return (
    <section id="demo" className="py-5" ref={sectionRef}>
      <div className="container">
        <div className="row">
          <div className="col-md-7 d-flex flex-column justify-content-center">
            <h1 style={{ fontSize: '48px', fontWeight: '600', textAlign: 'left', color: theme.h1 }}>AI-Powered Web and Phone Agent For Cannabis Dispensaries</h1>
            <p style={{ fontSize: '18px', textAlign: 'left', color: theme.h1SubText }}>Fully integrated web and phone agent that syncs seamlessly with your dispensary's menu to provide real-time product availability, current store specials, test results, terpene profiles, and so much more!</p>
          </div>
          <div className="col-md-5">
            <div className="rounded p-4 text-white" style={{ position: 'relative', backgroundColor: theme.demoCardBackground }}>
              <h2 className="mb-3" style={{ fontSize: '30px', textAlign: 'center', fontWeight: '600' }}>Try Our Demo Agent Now!</h2>
              <h2 className="mb-3" style={{ fontSize: '20px', textAlign: 'center' }}>Enter your phone number below to receive a call from our dispensary agent, Alex.</h2>
              <input
                type="tel"
                className="form-control border-0 rounded p-3 text-dark"
                placeholder="What's Your Phone Number?"
                style={{
                  outline: 'none',
                  height: '45px',
                  backgroundColor: (isPhoneValid || localPhoneNumber.length === 0) ? 'white' : '#FFCCCB'
                }}
                value={localPhoneNumber}
                onChange={handlePhoneNumberChange}
                onBlur={handlePhoneNumberBlur}
                disabled={isCallInProgress}
              />
              {errorMessage && (
                <div style={{
                  color: 'white',
                  marginTop: '5px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <span style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '20px',
                    minWidth: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    color: 'red',
                    fontWeight: 'bold',
                    marginRight: '5px',
                    fontSize: '14px'
                  }}>!</span>
                  {errorMessage}
                </div>
              )}
              <button
                className="button mt-3 w-100 white"
                style={{ backgroundColor: theme.demoButton }}
                onClick={handleButtonClick}
                disabled={isCallInProgress}
              >
                <img src="phone.png" alt="Logo" className="me-2" style={{ width: '16px', height: '20px' }} />
                {isCallInProgress ? 'Call in progress...' : 'Talk to Alex'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionDemo;