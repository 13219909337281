import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navigation from './components/Navigation';
import SectionDemo from './components/SectionDemo';
import SectionFeatures from './components/SectionFeatures';
import SectionPricing from './components/SectionPricing';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import CustomShapeDivider from './components/CustomShapeDivider';
import VerticalPad from './components/VerticalPad';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';
import './App.css';
import { createClient, SupabaseClient } from '@supabase/supabase-js'
import { ThemeProvider, useTheme, useThemeColors } from './ThemeContext';

// Define a type for your demo data
export type Demo = {
  id: number;
  slug: string;
  first_name: string;
  last_name: string;
  industry: string;
  company_name: string;
  creation_date: string;
  role: string;
  crm_type: string;
  agent_company_name: string;
  notes: string;
};

const Background: React.FC = () => {
  const style: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'radial-gradient(var(--plum) 1.15px, transparent 1.15px)',
    backgroundSize: '23px 23px',
    backgroundPosition: '0 0',
    backgroundRepeat: 'repeat',
    backgroundAttachment: 'fixed',
    maskImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 33.33%, rgba(0, 0, 0, 0) 33.33%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0) 80px, rgba(0, 0, 0, 1) 180px)',
    WebkitMaskImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 33.33%, rgba(0, 0, 0, 0) 33.33%, rgba(0, 0, 0, 0.5) 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0) 80px, rgba(0, 0, 0, 1) 180px)',
    maskComposite: 'intersect',
    WebkitMaskComposite: 'source-in',
    zIndex: -1,
  };

  return <div style={style} />;
};

const App: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [demo, setDemo] = useState<Demo | null>(null);
  const [isLeafCall, setIsLeafCall] = useState(false);
  const emailFrom = 'contact@clearanswerai.com';
  const emailTo = ['phil@clearanswerai.com', 'info@clearanswerai.com', 'nick@clearanswerai.com', 'jesse@clearanswerai.com'];
  const themeName = useTheme();

  useEffect(() => {
    setIsLeafCall(true);

    const fetchDemo = async () => {
      // Get the current URL
      const currentUrl = window.location.href;

      // Extract the part after "hostname:port/"
      const urlParts = currentUrl.split('/');
      let slug = urlParts[urlParts.length - 1];

      // Stop the slug at '#' or '?', whichever comes first
      const hashIndex = slug.indexOf('#');
      const queryIndex = slug.indexOf('?');

      if (hashIndex !== -1 && queryIndex !== -1) {
        // Both '#' and '?' are present, use the earlier one
        slug = slug.substring(0, Math.min(hashIndex, queryIndex));
      } else if (hashIndex !== -1) {
        // Only '#' is present
        slug = slug.substring(0, hashIndex);
      } else if (queryIndex !== -1) {
        // Only '?' is present
        slug = slug.substring(0, queryIndex);
      }
      // If neither '#' nor '?' is present, use the full slug

      if (slug) {
        const supabase: SupabaseClient = createClient('https://zsrbhkwknfhpqrvkzipj.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpzcmJoa3drbmZocHFydmt6aXBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc2NTM3NDUsImV4cCI6MjA0MzIyOTc0NX0.Xk1-sHGKU9TvnDEd0MaJQm_uiS3_jePumIF3oatGJlE')

        // Query Supabase
        const { data, error } = await supabase
          .from('demos')
          .select('*')
          .eq('slug', slug)
          .single();

        if (error) {
          console.error('Error fetching demo:', error);
        } else if (data) {
          setDemo(data);
        }
      }
    };

    fetchDemo();
  }, []); // Empty dependency array means this effect runs once on mount

  // New useEffect hook to update the document title
  useEffect(() => {
    document.title = "Leaf Call AI";
  }, [isLeafCall]);

  const handlePhoneNumberChange = (newPhoneNumber: string) => {
    setPhoneNumber(newPhoneNumber);
  };

  const MainContent = () => {
    const themeColors = useThemeColors();
    return (
      <div>
        <VerticalPad height={160} />
        <main>
          <SectionDemo
            phoneNumber={phoneNumber}
            onPhoneNumberChange={handlePhoneNumberChange}
            demo={demo}
            emailFrom={emailFrom}
            emailTo={emailTo}
          />
          <VerticalPad height={40} />
          <CustomShapeDivider />
          <SectionFeatures />
          <CustomShapeDivider flip={false} />
          {
            // demo don't show pricing at all right now
            false
              ? <>
                <VerticalPad height={40} />
                <SectionPricing />
              </>
              : ''
          }
          <VerticalPad height={40} />
          <ContactSection
            phoneNumber={phoneNumber}
            onPhoneNumberChange={handlePhoneNumberChange}
            demo={demo}
            emailFrom={emailFrom}
            emailTo={emailTo}
          />
        </main>
      </div>
    );
  };

  const AppContent = () => {
    const location = useLocation();

    return (
      <div className="App" style={{ minHeight: '100vh' }}>
        <Background />
        <Navigation demo={null} />
        <Routes>
          <Route path="*" element={<MainContent />} />
        </Routes>
        <Footer />
      </div>
    );
  };

  return (
    <ThemeProvider value="leafcall">
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
};

export default App;
