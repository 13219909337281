import { CSSProperties } from 'react';

interface StyleProps {
  color: string;
}

export const navLink = ({ color }: StyleProps): CSSProperties => ({
  color: color,
  textDecoration: 'none',
  margin: '0 15px',
  padding: '5px 0',
  transition: 'color 0.3s ease',
  position: 'relative',
});

export const navLinkHover = ({ color }: StyleProps): CSSProperties => ({
  color: color,
});

interface ActiveStyleProps extends StyleProps {
  activeColor: string;
}

export const navLinkActive = ({ activeColor }: ActiveStyleProps): CSSProperties => ({
  color: activeColor,
});

export const navLinkUnderline = ({ activeColor }: ActiveStyleProps): CSSProperties => ({
  position: 'absolute',
  bottom: '-2px',
  left: 0,
  width: '100%',
  height: '2px',
  backgroundColor: activeColor,
});

interface CustomShapeDividerStyleProps {
  fillColor1: string;
  fillColor2: string;
  fillColor3: string;
  flip?: boolean;
}

export const customShapeDividerStyle = ({
  fillColor1,
  fillColor2,
  fillColor3,
  flip = true,
}: CustomShapeDividerStyleProps) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  } as CSSProperties,
  divider: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    overflow: 'hidden',
    lineHeight: 0,
    transform: flip ? 'rotate(180deg)' : 'none',
  } as CSSProperties,
  svg: {
    position: 'relative',
    display: 'block',
    width: 'calc(100% + 1.3px)',
    height: '150px',
  } as CSSProperties,
  shapeFill1: {
    fill: fillColor1,
  } as CSSProperties,
  shapeFill2: {
    fill: fillColor2,
  } as CSSProperties,
  shapeFill3: {
    fill: fillColor3,
  } as CSSProperties,
});