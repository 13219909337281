import React, { createContext, useContext, ReactNode } from 'react';
import themes from './themes';

type Theme = 'leafcall';

const ThemeContext = createContext<Theme>('leafcall');

export const useTheme = () => useContext(ThemeContext);

export const useThemeColors = () => {
  const theme = useTheme();
  return themes[theme];
};

interface ThemeProviderProps {
  children: ReactNode;
  value: Theme;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, value }) => {
  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;